import {
    alert,
    confirm
} from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
export default {
    notifySuccess(msg) {
        notify({ message: msg, position: 'top' }, "success", 2000);
    },
    notifyError(msg) {
        notify({ message: msg, position: 'top' }, "error", 2500);
    },
    alert(msg, title) {
        if (title) {
            alert(msg, title);
        } else {
            alert(msg, 'Result');
        }
    },
    confirm(msg) {
        return confirm(msg, 'Confirm');
    },
    downLoadFile(e) {
        var columnName = e.column.dataField;
        if (columnName) {
            if (columnName.toUpperCase().includes('FILE') && e.value) {
                var filePath = e.data[columnName + 'Path'];
                window.location.href = '/fileDownload?file=' + encodeURIComponent(filePath);
            }
        }
    },
    getGridSubject(subject) {
        return "▣ " + subject;
    },
    formatNumber(value) {
        // 예시 포매팅: 숫자에 콤마 삽입
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isApp() {
        var result = true;

        if (navigator.userAgent.toUpperCase().includes('MOBILE')) {
            result = true;
        } else {
            result = false;
        }

        return result
    },
}